import { Injectable } from '@angular/core';
import { CardSettings, CardSettingsDTO } from './card.model';

@Injectable({
  providedIn: 'root',
})
export class CardService {
  mapCardSettings(settings: CardSettingsDTO): CardSettings | null {
    if (settings) {
      return {
        backgroundColor: settings.cardBackgroundColor,
        buttonBackgroundColor: settings.cardButtonBackgroundColor,
        buttonForegroundColor: settings.cardButtonForegroundColor,
        buttonOnHoverBackgroundColor: settings.cardButtonOnHoverBackgroundColor,
        buttonOnHoverForegroundColor: settings.cardButtonOnHoverForegroundColor,
        foregroundPrimaryColor: settings.cardForegroundPrimaryColor,
        foregroundSecondaryColor: settings.cardForegroundSecondaryColor,
      };
    }
    return null;
  }
}
